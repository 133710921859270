import Image from 'next/image';
import { Button } from '@/components/buttons/Button';
import { Text } from '@/components/Text';
import { BaseModal, closeModal } from '../BaseModal';
import { Modals } from '@/types/modals';
import SavedIcon from '@/assets/icons/saved-icon.svg';
import SecureIcon from '@/assets/icons/secure-icon.svg';
import AnonymousIcon from '@/assets/icons/anonymous-icon.svg';
import CloseIcon from '@/assets/icons/close-icon.svg';
import { useAppStore } from '@/stores/app';
import { SocialProvidersEnum } from '@/types/auth';
import { getSocialIconByProvider } from '@/helpers/socialMedia';
import { clearPersist, clearWelcomMessagesMap } from '@/helpers/localStorage';
import { useCharactersStore } from '@/stores/characters';
import { usePathname } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import posthog from 'posthog-js';
import { sendSignInLink, sendPasswordResetEmail } from '@/lib/firebase';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

interface SignInModalProps {
  isVisible?: boolean;
  onClose?: () => void;
}

export default function SignInModal({ isVisible, onClose }: SignInModalProps) {
  const { signInUiType, signInRedirectUrl, preSignInCharacter, preSignInCharacters, setSignInUiType } = useAppStore(s => s);

  const [signInMethod, setSignInMethod] = useState<'password' | 'magic-link'>('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const resetCharacters = useCharactersStore(s => s.reset);
  const pathname = usePathname();
  const router = useRouter();  
  const [character, setCharacter] = useState(preSignInCharacter);
  const [avatar, setAvatar] = useState(character?.avatar_url);
  const [countdown, setCountdown] = useState<number | null>(null);
  const characterCreation = signInRedirectUrl?.includes('character/create');

  useEffect(() => {
    if (characterCreation && preSignInCharacters) {
      const pickRandomCharacter = () => {
        const randomIndex = Math.floor(Math.random() * preSignInCharacters.length);
        const randomCharacter = preSignInCharacters[randomIndex];
        setCharacter(randomCharacter);
        setAvatar(randomCharacter.avatar_url);
      };

      // Initial pick
      pickRandomCharacter();

      // Set up interval to change character every 3 seconds
      const intervalId = setInterval(pickRandomCharacter, 3000);

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
    else if (!preSignInCharacter) {
      const characters = useCharactersStore.getState().characters;
      if (characters.length > 0) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        const randomCharacter = characters[randomIndex];
        setCharacter(randomCharacter);
        setAvatar(randomCharacter.avatar_url);
      }
    }
    else {
      setCharacter(preSignInCharacter);
      setAvatar(preSignInCharacter.avatar_url);
    }
  }, [preSignInCharacter, characterCreation, preSignInCharacters]);


  useEffect(() => {
    if (isVisible && character && !preSignInCharacter) {
      const characters = useCharactersStore.getState().characters;
      if (characters.length > 0) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        const randomCharacter = characters[randomIndex];
        setCharacter(randomCharacter);
        setAvatar(randomCharacter.avatar_url);
      }
    }
  }, [isVisible]);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (signInMethod === 'password') {
      setDisabled(!(password && isValidEmail(email)));
    } else {
      setDisabled(!isValidEmail(email) || countdown !== null);
    }
  }, [password, email, signInMethod, countdown]);

  const toggleUiType = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default anchor behavior
    setSignInUiType(signInUiType === 'signinUI' ? 'signupUI' : 'signinUI');
    setErrorMessage('');
  };

  const toggleSignInMethod = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default anchor behavior
    setSignInMethod(prevMethod => prevMethod === 'password' ? 'magic-link' : 'password');
    setErrorMessage('');
  };
  
  function disableEmailLinkButton() {
    setCountdown(60); // Start from 60 seconds

    const intervalId = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === null || prevCount <= 1) {
          clearInterval(intervalId);
          return null;
        }
        return prevCount - 1;
      });
    }, 1000);
  }

  const cleanUpSignIn = () => {
    resetCharacters();
    clearPersist();
    clearWelcomMessagesMap();
  }

  const handleEmailSignIn = async () => {
    if (signInMethod === 'password') {
      const result = await signIn('credentials', { 
        email: email, 
        password: password, 
        newUser: (signInUiType === 'signupUI')? true : false,
        signInMethod: 'password', 
        redirect: false
      });
      if (result?.error) {
        setErrorMessage(result.error);
      } else {
        closeModal(Modals.SignIn);
        cleanUpSignIn();
        if (signInRedirectUrl) {
          router.push(signInRedirectUrl);
        } else {
          router.refresh();
        }
      }

      posthog?.capture('authentication_attempt', {'provider': 'credentials'});
    } else {
      try {
        const result = await sendSignInLink(email, signInRedirectUrl || pathname);
        setErrorMessage(`Email link sent,check your inbox (and Spam folder)!`);
        disableEmailLinkButton()
      } catch (error) {
        setErrorMessage(`${error}`);
      }
    }
  }

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(email);
      setResetEmailSent(true);
    } catch (error) {
      setErrorMessage(`Error sending password reset email: ${error}`);
    }
  }

  const GoogleIcon = getSocialIconByProvider(SocialProvidersEnum.GOOGLE);
  const DiscordIcon = getSocialIconByProvider(SocialProvidersEnum.DISCORD);
  const EmailIcon = getSocialIconByProvider(SocialProvidersEnum.CREDENTIALS);
  async function handleGoogleSignIn() {
    closeModal(Modals.SignIn);
    cleanUpSignIn();
    await signIn('google', { callbackUrl: signInRedirectUrl || pathname });

    posthog?.capture('authentication_attempt', {'provider': 'google'});
  }

  async function handleDiscordSignIn() {
    closeModal(Modals.SignIn);
    cleanUpSignIn();
    signIn('discord', { callbackUrl: signInRedirectUrl || pathname });

    posthog?.capture('authentication_attempt', {'provider': 'discord'});
  }

  // Use onClose when closing the modal
  const handleClose = () => {
    closeModal(Modals.SignIn)
    onClose?.();
  }

  return (
    <BaseModal
      className="h-[calc(100vh)] overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto backdrop-filter backdrop-blur-md bg-black bg-opacity-50"
      id={Modals.SignIn}
      onClose={handleClose}
    >
      <div className="flex flex-col sm:flex-row items-center justify-center border border-gray-700 bg-black rounded-3xl overflow-hidden h-[520px] max-w-[calc(100vw-20px)]">
        <div className="relative w-[400px] h-full hidden sm:block">
          {avatar && character && (
            <Image
              src={avatar}
              alt={character.name}
              layout="fill"
              objectFit="cover"
              className="w-full h-full"
            />
          )}
          <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-center bg-gradient-to-b from-black/0 via-black/25 to-black/100">
            {characterCreation ? (
              <>
                <Text className="text-white w-11/12 items-left mt-40 mb-5.5 text-2xl" variant="h4">
                  Sign in to continue.
                </Text>
                <div className="flex items-center bg-black/75 rounded-lg mb-2">
                  <SavedIcon className="ml-2 mr-2" />
                  <Text className="text-white/70 flex mr-2 items-left mb-5.5 text-lg" variant="body-2">
                  Your choices will be saved
                </Text>
                </div>
              </>
            ) : (
              <>
                <Text className="text-white w-11/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
                  Wanna talk with me?
                </Text>
                <Text className="text-white w-11/12 items-left mb-5.5 text-sm" variant="body-2">
                  Sign in and message <span className="text-white">{character?.name}</span> for
                  <span className="text-white non-italic font-bold"> FREE</span>
                </Text>
              </>
            )}

            <div className="flex flex-row items-center mt-2">
              <div className="flex items-center">
                <SecureIcon />
                <Text className="text-white text-xs ml-1 mr-1">Secured</Text>
              </div>
              <div className="h-[10px] ml-1 mr-1 w-[1px] bg-white/[0.5]"></div>
              <AnonymousIcon className="ml-1 mb-0.5" />
              <Text className="text-white text-xs ml-1">Anonymous</Text>
            </div>
          </div>
        </div>


        <div className="flex flex-col items-center w-[400px] max-w-full relative bg-black-100 h-full">
          <button
            onClick={handleClose}
            className="absolute right-2 top-2 rounded-full bg-white/[0.25] p-1 ring-offset-background transition-opacity hover:opacity-100 hover:bg-white/[0.5] disabled:pointer-events-none"
            aria-label="Close"
          >
            <CloseIcon />
          </button>
          
          <div className="flex flex-grow flex-col items-center mt-10 w-full">
            <Text className="text-white w-11/12 items-left mt-5 mb-5.5 text-2xl" variant="h3">
              {signInUiType === 'signinUI' ? 'Sign in' : 'Create Account'}
            </Text>
            <div className="w-11/12 m-4">
              <input
                type="email"
                placeholder="E-mail"
                className="w-full p-2 mb-2 rounded bg-black-200 text-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {signInMethod === 'password' && (
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full p-2 mb-2 rounded bg-black-200 text-white"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
              {signInMethod === 'password' ? (
                <Text className="text-white/70 w-11/12 mt-1 text-sm" variant="body-2">
                  Login with <a href="#" onClick={toggleSignInMethod} className="text-pink">email link</a>
                </Text>
              ) : (
                <Text className="text-white/70 w-11/12 mt-1 text-sm" variant="body-2">
                  Login with <a href="#" onClick={toggleSignInMethod} className="text-pink">password</a>
                </Text>
              )}
            </div>
            <Button
              onClick={handleEmailSignIn}
              className="w-11/12 mt-2 bg-pink-500"
              wrapperClass={`py-[10px] bg-blue-gr group-active/button:bg-pink-700 ${disabled ? 'bg-gray/80' : 'bg-blue-gr'}`}
              disabled={disabled}
            >
              <Text
                className="transition-all duration-150 text-white group-active/button:text-black"
                textColor="white"
                variant="btn-1"
              >
                {signInMethod === 'password' ? 
                  signInUiType === 'signinUI' ? 'Sign In' : 'Create Free Account' 
                  : countdown !== null ? `Resend in ${countdown}s` : 'Send Email Link'}
              </Text>
            </Button>
          </div>

          <div className="w-11/12 flex-col p-2 text-center rounded overflow-x-hidden">
            <div className="whitespace-nowrap text-red-500">
              {errorMessage}
            </div>
            {errorMessage && signInMethod === 'password' && signInUiType === 'signinUI' && !resetEmailSent && (
              <div className="whitespace-nowrap text-green-500">
                Forgot password? <a href="#" onClick={handleResetPassword} className="text-sky-600"> Reset password</a>
              </div>
            )}
            {errorMessage && signInMethod === 'password' && signInUiType === 'signinUI' && resetEmailSent && (
              <div className="whitespace-nowrap text-sky-600">
                  Reset email sent, please check your inbox!
              </div>
            )}
          </div>

          <div className="flex flex-col items-center w-[400px] bottom-0 mb-3">
            <div className="flex flex-row w-10/12 items-center pt-1 pb-5">
              <div className="flex-1 h-[1px] bg-gradient-to-l from-white/50 to-transparent"></div>
              <div className="flex-0 text-white text-sm font-medium mx-4">or continue with</div>
              <div className="flex-1 h-[1px] bg-gradient-to-r from-white/50 to-transparent"></div>
            </div>
            <div className="flex items-center justify-center w-10/12">
              <Button
                onClick={handleGoogleSignIn}
                className="w-full mx-1"
                wrapperClass="py-[10px] bg-white group-active/button:bg-white-gr"
              >
                <Text
                  className="transition-all duration-150 text-black group-active/button:text-black"
                  textColor="black"
                  variant="btn-1"
                >
                  Google
                </Text>
                <div className="flex items-center justify-center w-6 h-6 border bg-white rounded-lg ml-2">
                  <GoogleIcon />
                </div>
              </Button>
              <Button
                onClick={handleDiscordSignIn}
                className="w-full mx-1"
                wrapperClass="py-[10px] bg-white group-active/button:bg-blue-700"
              >
                <Text
                  className="transition-all duration-150 text-black group-active/button:text-black"
                  textColor="black"
                  variant="btn-1"
                >
                  Discord
                </Text>
                <div className="flex items-center justify-center w-6 h-6 border bg-white rounded-lg ml-2">
                  <DiscordIcon />
                </div>
              </Button>
            </div>
          </div>
          
          <div className="flex flex-col bottom-0 w-full ">
            <hr className="border-gray/100 border-t-1 mt-4 w-full" />
            <Text className="text-white w-11/12 text-center mt-3 mb-3 text-sm " variant="h2">
              {signInUiType === 'signinUI' ? (
                <>
                  Don't have an account yet? <a href='#' className='text-pink' onClick={toggleUiType}>Create Acount</a>
                </>
              ) : (
                <>
                  Already have an account? <a href='#' className='text-pink' onClick={toggleUiType}>Sign In</a>
                </>
              )}
            </Text>
          </div>
        </div>
      </div>
    </BaseModal>
  );



}

