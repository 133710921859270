import api, { callApi } from './config';
import { IError } from '@/models/common';
import { ICharacterUnlockedImages, IImageGenerationRequest, IImageRequestFilter } from '@/models/image';

const imageApi = {
  async getUnlockedImages() {
    const url = '/image/get_unlocked_images';
    return callApi<ICharacterUnlockedImages, IError>(api.get(url));
  },
  async getImageRequest(id: number) {
    const url = `/image/request/${id}`;
    return callApi<IImageGenerationRequest, IError>(api.get(url));
  },
  async getFilteredImageRequests(filters: IImageRequestFilter) {
    const url = `/image/filter_requests`;
    const simplifiedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value && value !== undefined && value !== '') {
        acc[key] = typeof value === 'object' && value !== null ? value.value : value;
      } else {
        delete acc[key];
      }
      return acc;
    }, {} as Record<string, any>);
    return callApi<IImageGenerationRequest[], IError>(api.get(url, { params: simplifiedFilters }));
  },
};

export default imageApi;
