import Image from 'next/image';
import { Modals } from '@/types/modals';
import { StoreCard } from '@/components/StoreCard';
import { cn } from '@/lib/utils';
import { Text } from '@/components/Text';
import { BaseModal, closeModal, openModal } from '../BaseModal';
import crystalImage from '@/assets/images/crystal-image.png';
import StorageBanner from '@/assets/images/vector/storage-banner.svg';
import { useProductsStore } from '@/stores/products';
import { Product, ProductType } from '@/types/products';
import { useUserStore } from '@/stores/user';
import { Button } from '../buttons/Button';
import { AnimatedCounter } from '@/components/AnimatedCounter';
import SecureIcon from '@/assets/icons/secure-icon.svg';
import AnonymousIcon from '@/assets/icons/anonymous-icon.svg';
import DollarIcon from '@/assets/icons/dollar-icon.svg';
import posthog from 'posthog-js';

export const StorageModal: React.FC = () => {
  const {
    products,
    claimDailyGems,
    checkoutProduct,
    selectedProductId,
    loading,
    claimDailyGemsLoading,
    clearSelectedProduct,
  } = useProductsStore(s => s);
  const { userStats, getUserStats } = useUserStore();

  const getBestValueProduct = () => {
    const validProducts = products.filter(p => p.original_price && p.usd_cents && p.type !== ProductType.Subscription);
    return validProducts.reduce<Product | null>((best, product) => {
      const bestDiscount = best?.original_price ? best.original_price / best.usd_cents : 0;
      const currentDiscount = product.original_price ? product.original_price / product.usd_cents : 0;
      return currentDiscount > bestDiscount ? product : best;
    }, null);
  };
  const bestValueProduct = getBestValueProduct();

  const showProductCheckoutModal = async (selectedProduct: Product) => {
    await checkoutProduct(selectedProduct.id);
    openModal(Modals.ProductCheckout);
  };

  const handleBuyProduct = (product: Product) => {
    clearSelectedProduct();
    const productType = product?.type;
    if (productType === ProductType.DailyGem) {
      claimDailyGems();
    } else if (productType === ProductType.GemBundle || productType === ProductType.Subscription) {
      if (product) {
        showProductCheckoutModal(product);
        posthog?.capture('checkout', {'product_id': product.id, 'product_type': productType, 'value': product.usd_cents});
      }
    }
  };

  return (
    <BaseModal
      className="h-[calc(100vh)] overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto backdrop-filter backdrop-blur-md bg-black bg-opacity-50"
      id={Modals.Storage}
    >
      <div className={cn('relative rounded-[32px] w-full max-w-[800px] p-0')}>
        <div className="relative bg-black-200 rounded-[inherit] p-4 z-10">
          <Button
            onClick={() => closeModal(Modals.Storage)}
            className={'w-auto sm:hidden'}
            wrapperClass={'bg-white py-1 group-active/button:bg-black'}
          >
            <Text className="px-5 text-black group-active/button:text-white" variant="btn-2">
              Close
            </Text>
          </Button>

          {/* Your balance session */}
          <div className="flex items-center justify-end mb-4">
            <Text textColor="white" className="mr-2" variant="h5">
              Your balance:
            </Text>
            <div className="w-[24px] h-[24px] flex items-center justify-center w-10 h-10 bg-black-100 rounded-full mr-[6px] relative">
              <div className="w-[24px] h-[24px] z-10 flex items-center justify-center w-10 h-10 bg-black-100 rounded-full">
                <Image className="w-[15px] h-[15px]" src={crystalImage} alt="crystal" />
              </div>
              <div className="z-0 w-[24px] h-[24px] flex items-center justify-center w-10 h-10 bg-pink rounded-full absolute left-[1px] top-[1px]"></div>
            </div>
            <Text textColor="white" variant="btn-1">
              <AnimatedCounter amount={userStats.gem_balance} />
            </Text>
          </div>

          {/* Upgrade your experience with gems session */}
          <div className="hidden sm:flex mb-10 relative items-center w-full">
            <StorageBanner className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-gradient-to-r from-black/30 via-black/10 to-transparent rounded-lg"></div>
            <div className="absolute inset-0 left-4 flex flex-row items-center justify-center">
              <div className="flex items-center">
                <Text variant="h1" className="text-h3 md:text-h1 text-outline-white">
                  💎
                </Text>
              </div>
              <Text variant="h1" textColor="white" className="ml-2 mr-2 text-h5 text-sm sm:text-md sm:text-h3 md:text-h1 text-outline-black">
                Premium Gem Store
              </Text>
              <div className="flex items-center">
                <Text variant="h1" className="text-h3 md:text-h1 text-outline-white">
                  💎
                </Text>
              </div>
            </div>
          </div>

          {/* Products session */}
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-1 sm:gap-3">
            {products.filter(p => p.type !== ProductType.Subscription).length > 0 &&
              products.filter(p => p.type !== ProductType.Subscription).slice(0, 6).sort((a, b) => (a.usd_cents ?? 0) - (b.usd_cents ?? 0)).map((product, i) => {
                const isFree = product.type === ProductType.DailyGem;
                const isSelectedProduct = selectedProductId === product.id;
                return (
                  <StoreCard
                    product={product}
                    isBestValue={(bestValueProduct && product.id === bestValueProduct.id) || false}
                    key={product.id || i}
                    onPurchase={() => handleBuyProduct(product)}
                    loading={(isFree && claimDailyGemsLoading) || isSelectedProduct ? loading : false}
                    isSelected={isSelectedProduct}
                  />
                );
              })}
          </div>

          {/* Footer session */}
          <div className="w-full flex flex-row items-center justify-center mt-4">
            <AnonymousIcon className="ml-1 mb-0.5" />
            <Text className="text-white text-xs ml-1" variant="body-1">
              100% Anonymous
            </Text>
            <div className="h-[10px] ml-1 mr-1 w-[1px] bg-white/[0.5]"></div>
            <DollarIcon className="ml-1 mb-0.5" />
            <Text className="text-white text-xs ml-1" variant="body-1">
              No recurring charges
            </Text>
            <div className="h-[10px] ml-1 mr-1 w-[1px] bg-white/[0.5]"></div>
            <div className="flex items-center">
              <SecureIcon />
              <Text className="text-white text-xs ml-1 mr-1" variant="body-1">
                Safe & Secure Payments
              </Text>
            </div>
          </div>

        </div>
      </div>
    </BaseModal>
  );
};
