import LocalStorageUtils from '@/utils/storage';
import { PropsWithChildren, useEffect, useState } from 'react';
import { toTitleCase } from '../../utils/string';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { useProductsStore } from '@/stores/products';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'next/router';
import { openModal, closeModal } from '../BaseModal';
import { Modals } from '@/types/modals';
import { ProductType } from '@/types/products';
import { useAppStore } from '@/stores/app';
import { IS_ONBOARDED } from '@/contants/storageKeys';
import SignInModal from '@/components/modals/SignIn';
import { StorageModal } from '@/components/modals/Storage';
import { ConfirmationModal } from '@/components/modals/Confirmation';
import { SuccessPaymentModal } from '@/components/modals/SuccessPayment';
import { WelcomeModal } from '@/components/modals/Welcome';
import { SuccessSubscriptionModal } from '@/components/modals/SuccessSubscription';
import { ProductCheckoutModal } from '@/components/modals/ProductCheckout';
import { FailurePaymentModal } from '@/components/modals/FailurePayment';
import posthog from 'posthog-js';

interface Props {
  pathName: string;
}

export function AppLayout({ pathName, children }: PropsWithChildren<Props>) {
  const {
    products,
    selectedProductId,
    clearSelectedProduct,
    getProductsAndPromotions,
  } = useProductsStore(s => s);
  const [checkoutErrorMsg, setCheckoutErrorMsg] = useState<string | null>(null);
  const isSignedIn = useIsAuthenticated();
  const {initUser, getUserStats} = useUserStore(s => ({initUser: s.initialize, getUserStats: s.getUserStats}));
  const initCharacters = useCharactersStore(s => s.initialize);
  const { banners, isSignInModalVisible, getBanners, setSignInUiType, setIsSignInModalVisible, confirmation, showConfirmation } = useAppStore(s => s);
  const router = useRouter();
  const isOnboarded = LocalStorageUtils.getItem<boolean>(IS_ONBOARDED);

  useEffect(() => {
    if (confirmation) {
      openModal(Modals.GenericConfirmation);
    }
  }, [confirmation]);

  useEffect(() => {
    if (router.query?.auth_required && !isSignedIn) {
      setIsSignInModalVisible(true);
      setSignInUiType('signinUI');
      openModal(Modals.SignIn);
      posthog?.capture('signin_display_app_layout_query');
    }
  }, [router.query, isSignedIn]);

  useEffect(() => {
    if (initCharacters) {
      initCharacters();
    }
  }, [initCharacters]);

  useEffect(() => {
    if (!banners?.length) getBanners();
  }, [getBanners, banners?.length]);

  useEffect(() => {
    if (isSignedIn && initUser) {
      initUser();
      if (!isOnboarded) {
        openModal(Modals.Welcome);
      }
    }
  }, [isSignedIn, isOnboarded, initUser]);

  const onCheckoutSucceeded = async () => {
    closeModal(Modals.ProductCheckout);
    closeModal(Modals.Storage);
    await getUserStats();
    const selectedProduct = products.find(p => p.id === selectedProductId);
    if (selectedProduct?.type === ProductType.Subscription) {
      openModal(Modals.SuccessSubscription);
      posthog?.capture('purchased_subscription', {'product_id': selectedProductId});
    } else {
      openModal(Modals.SuccessPayment);
      posthog?.capture('purchase', {'product_id': selectedProductId});
    }
    await getProductsAndPromotions();
    clearSelectedProduct();
  };

  const onCheckoutError = async (error: string) => {
    setCheckoutErrorMsg(error);
    openModal(Modals.FailurePayment);
    await getProductsAndPromotions();
    clearSelectedProduct();
  };

  const closeSignInModal = () => setIsSignInModalVisible(false);

  return (
    <>
      <div className="main">{children}</div>
      <SignInModal isVisible={isSignInModalVisible} onClose={closeSignInModal}/>
      <ConfirmationModal confirmation={confirmation} onClose={() => showConfirmation(null)}/>
      <WelcomeModal />
      {isSignedIn && (
        <div>
          <StorageModal />
          <ProductCheckoutModal onPaymentSucceeded={onCheckoutSucceeded} onPaymentError={onCheckoutError} />
          <FailurePaymentModal message={checkoutErrorMsg || 'An error occurred while processing your payment'} />
          <SuccessPaymentModal />
          <SuccessSubscriptionModal />
        </div>
      )}
    </>
  );
}
