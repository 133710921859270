import { callWithLoading, stateBase } from '@/helpers/store';
import { StateBase } from '@/types/store';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { persist } from 'zustand/middleware';
import { ICharacterUnlockedImages, IImageDataInCollection } from '@/models/image';
import imageApi from '@/api/image';
import { isImageURLExpired } from '@/lib/utils';

export interface ImageState extends StateBase {
  loading: boolean;
  updateRequired: boolean;
  characterImages: ICharacterUnlockedImages;
  getUnlockedImages: () => Promise<boolean>;
  setUpdateRequired: (value: boolean) => void;
  deleteImages: (characterId: number, imageIds: number[]) => void;
}

export const imageStorePersistKey = 'image-store';

export const useImageStore = create<ImageState>()(
  persist(
    (set, get) => ({
      ...stateBase,
      updateRequired: false,
      characterImages: [],
      getUnlockedImages: async () => {
        set({ loading: true });
        try {
          const res = await callWithLoading<ICharacterUnlockedImages | AxiosError<any, any>>(imageApi.getUnlockedImages(), set);
          if (res instanceof AxiosError) {
            showToast(`Can\'t get images: ${res.response?.data?.detail || res.message}`, ToastType.ERROR);
          } else {
            set({ characterImages: res });
            set({ loading: false });
            set({ updateRequired: false });
            return true;
          } 
        } catch (error) {
          console.error('Failed to get unlocked images:', error);
          showToast('Failed to fetch unlocked images', ToastType.ERROR);
        }
        set({ loading: false });
        set({ updateRequired: false });
        return false;
      },
      setUpdateRequired: (value: boolean) => {
        set({ updateRequired: value });
      },
      deleteImages: async (characterId: number, imageIds: number[]) => {
        const currentImages = get().characterImages;
        const updatedCharacterImages = {
          ...currentImages,
          [characterId]: currentImages[characterId]?.filter(
            img => !imageIds.includes(img.image_id)
          )
        };
        set({ characterImages: updatedCharacterImages });
      },
    }),
    {
      name: imageStorePersistKey,
      partialize: state => ({
        characterImages: state.characterImages,
        updateRequired: state.updateRequired,
      }),
      onRehydrateStorage: () => (state) => {
        if (state) {
          const expired = Object.values(state.characterImages).some(characterImages => 
            characterImages.some((image: IImageDataInCollection) => {
              return isImageURLExpired(image.image_url);
            })
          );
          if (expired) {
            state.setUpdateRequired(true);
            state.getUnlockedImages();
          }
        }
      },
    },
  )
);
