import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getDeviceTags(): string[] {
  if (/Mobi|Android/i.test(navigator.userAgent)) {
    return ['mobile']
  } else {
    return ['desktop']
  }
}

export function getUTMTags(): string[] {
  const urlParams = new URLSearchParams(window.location.search);
  const utmTags: string[] = [];

  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
    const value = urlParams.get(param);
    if (value) {
      utmTags.push(value);
    }
  });

  return Array.from(new Set(utmTags));
}

export const scrollToElement = (elementId: string) => {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ block: "center", inline: "nearest", behavior: 'smooth' });
    }
  }, 100);
};


export const isImageURLExpired = (url?: string): boolean => {
  if (!url) return false;
  try {
    const imageUrl = new URL(url);
    const amzDate = imageUrl.searchParams.get('X-Amz-Date');
    const amzExpires = imageUrl.searchParams.get('X-Amz-Expires');
    if (!amzDate || !amzExpires) return false;

    const date = new Date(
      Date.UTC(
        parseInt(amzDate.substring(0, 4)), // Year
        parseInt(amzDate.substring(4, 6)) - 1, // Month (0-based)
        parseInt(amzDate.substring(6, 8)), // Day
        parseInt(amzDate.substring(9, 11)), // Hour
        parseInt(amzDate.substring(11, 13)), // Minute
        parseInt(amzDate.substring(13, 15)) // Second
      )
    );

    const expiresInSeconds = parseInt(amzExpires);
    const expireDate = new Date(date.getTime() + expiresInSeconds * 1000);
    return expireDate < new Date(Date.now() + 24 * 3600 * 1000);
  } catch (error) {
    console.error('Error parsing image_data URL:', error);
    return false;
  }
};

